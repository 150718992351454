<template>
  <b-row class="pricing-card">
    <b-col
      v-for="(item, index) in plans"
      :key="index"
      class="mx-auto"
      md="4"
    >
      <b-card align="center">
        <div
          v-show="true"
          class="pricing-badge text-center"
        >
          <b-badge
            variant="light-info"
            pill
            class="font-small-4"
          >
            <span class="py-1 px-1">
              {{ item.plan }}
            </span>
          </b-badge>
        </div>

        <div class="annual-plan">
          <div class="plan-price mt-2">
            <sup class="font-medium-1 font-weight-bold text-primary">R$ </sup>
            <span
              class="pricing-basic-value font-weight-bolder text-primary"
            >{{ item.price }}</span>
          </div>
        </div>

        <b-list-group class="list-group-circle text-left d-flex justify-content-center">
          <b-list-group-item
            v-for="(details) in JSON.parse(item.details)"
            :key="details"
          >
            {{ details }}
          </b-list-group-item>
        </b-list-group>

        <b-button
          block
          variant="relief-primary"
          class="mt-1"
          :disabled="loading"
          @click.stop="purchasePlanConfirm(item)"
        >
          <span v-if="!loading">Comprar</span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BRow, BCol, BBadge, BSpinner, BButton, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import IndexMixin from './IndexMixin'
import PurchaseMixin from './PurchaseMixin'
import checkSubscription from '../checkSubscription'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BBadge,
    BButton,
    BSpinner,
    BListGroup,
    BListGroupItem
  },
  mixins: [IndexMixin, PurchaseMixin, checkSubscription]
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
